import { Routes, Route, Navigate, RoutesProps } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import { useSelector } from "react-redux";
import { RootState } from "../config/Store";
import { UserState } from "../reducer/AuthReducer";
import Login from "../components/Login/Login";
import ForgotPassword from "../components/Login/ForgotPassword";
import Otp from "../components/Login/Otp";
import ResetPassword from "../components/Login/ResetPassword";
import TabSubCategories from "../pages/ManageCategory/TabSubCategory";
import WebService from "../Services/WebService";
import Dummy from "../pages/Dummy/Dummy";
import PdfDocumentPage from "../pages/PdfDocument/PdfDocumentPage";
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const ManageCategory = React.lazy(() => import("../pages/ManageCategory/ManageCategory"));
const ManageProducts = React.lazy(() => import("../pages/ManageProducts/ManageProducts"));
const AddProduct = React.lazy(() => import("../pages/ManageProducts/AddProduct"));
const ManageOrders = React.lazy(() => import("../pages/MangeOrders/MangeOrders"));
const OrderDetail = React.lazy(() => import("../pages/MangeOrders/OrderDetail"));
const ManageCustomers = React.lazy(() => import("../pages/ManageCustomers/ManageCustomers"));
const EditCustomer = React.lazy(() => import("../pages/ManageCustomers/EditCustomer"));
const UserManagement = React.lazy(() => import("../pages/AdminUserManagement/AdminUserManagement"));
const RoleManagement = React.lazy(() => import("../pages/AdminRoleManagement/AdminRoleManagement"));
const LogManagement = React.lazy(() => import("../pages/AdminLogManagment/AdminLogManagement"));
const AddRole = React.lazy(() => import("../pages/AdminRoleManagement/AddRole"));
const BlackListedDomain = React.lazy(() => import("../pages/BlackListedDomain/BlackListedDomain"));
const Subscriptions = React.lazy(() => import("../pages/Subscriptions/Subscriptions"));
const OffersDiscount = React.lazy(() => import("../pages/OffersDiscount/OffersDiscount"));
const AddOffer = React.lazy(() => import("../pages/OffersDiscount/AddOffer"));
const Settings = React.lazy(() => import("../pages/Settings/Settings"));
const Profile = React.lazy(() => import("../pages/Profile/Profile"));
const ManageNotification = React.lazy(() => import("../pages/ManageNotification/ManageNotification"));
const pdfDocumentPage = React.lazy(() => import("../pages/PdfDocument/PdfDocumentPage"));

interface ProtectedRouteProps extends RoutesProps {
  isAuthenticated: boolean;
  authenticationPath: string;
};




const Navigation = () => {
  const login: any = useSelector<RootState, UserState>((state: any) => state.userLogin);
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: localStorage.getItem("token") != null,
    authenticationPath: "/login",
  };




  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/login" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to="/dashboard" />) : (<Login />)} />
          <Route path="/forgot-password" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to="/dashboard" />) : (<ForgotPassword />)} />
          <Route path="/otp" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to="/dashboard" />) : (<Otp />)} />
          <Route path="/reset-password" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to="/dashboard" />) : (<ResetPassword />)} />

          <Route path="/" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Main />} />}>
            <Route path="*" element={<Dummy />} />
            <Route path="/dashboard" element={<Suspense fallback={<></>}> <Dashboard /> </Suspense>} />
            <Route path="/manage-category" element={<Suspense fallback={<></>}> <ManageCategory /> </Suspense>} />
            <Route path="/manage-sub-category/:id" element={<Suspense fallback={<></>}> <TabSubCategories /> </Suspense>} />
            <Route path="/manage-products" element={<Suspense fallback={<></>}> <ManageProducts /> </Suspense>} />
            <Route path="/add-product" element={<Suspense fallback={<></>}> <AddProduct /> </Suspense>} />
            <Route path="/mange-orders" element={<Suspense fallback={<></>}> <ManageOrders /> </Suspense>} />
            <Route path="/order-details/:id" element={<Suspense fallback={<></>}> <OrderDetail /> </Suspense>} />
            <Route path="/manage-customers" element={<Suspense fallback={<></>}> <ManageCustomers /> </Suspense>} />
            <Route path="/edit-customer" element={<Suspense fallback={<></>}> <EditCustomer /> </Suspense>} />
            <Route path="/user-management" element={<Suspense fallback={<></>}> <UserManagement /> </Suspense>} />
            <Route path="/role-management" element={<Suspense fallback={<></>}> <RoleManagement /> </Suspense>} />
            <Route path="/log-management" element={<Suspense fallback={<></>}> <LogManagement /> </Suspense>} />
            <Route path="/add-role" element={<Suspense fallback={<></>}> <AddRole /> </Suspense>} />
            <Route path="/blacklisted-domain" element={<Suspense fallback={<></>}> <BlackListedDomain /> </Suspense>} />
            <Route path="/subscriptions" element={<Suspense fallback={<></>}> <Subscriptions /> </Suspense>} />
            <Route path="/offers" element={<Suspense fallback={<></>}> <OffersDiscount /> </Suspense>} />
            <Route path="/add-offer" element={<Suspense fallback={<></>}> <AddOffer /> </Suspense>} />
            <Route path="/settings" element={<Suspense fallback={<></>}> <Settings /> </Suspense>} />
            <Route path="/profile" element={<Suspense fallback={<></>}> <Profile /> </Suspense>} />
            <Route path="/manage-notification" element={<Suspense fallback={<></>}> <ManageNotification /> </Suspense>} />
            <Route path="/pdfDocumentPage/:documentId/:page_index" element={<Suspense fallback={<></>}> <PdfDocumentPage /> </Suspense>} />
          </Route>
        </Routes >
      </div >
    </>
  );
};

export default Navigation;
