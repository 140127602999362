import { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Form, Button, Offcanvas } from 'react-bootstrap'
import Grid, { GridColumn, GridHeader, GridRow } from '../../components/Grid/Grid';
import WebService from '../../Services/WebService';
import HelperService from '../../Services/HelperService';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { FaRegTrashAlt } from 'react-icons/fa';
import DeleteModal from '../../components/Common/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import Loader from '../../components/Common/Loader/Loader';
import {  useForm } from 'react-hook-form';
import { Label } from '../../components/Common/Label/Label';
import PageTitle from '../../components/Common/PageTitle';
import { useParams } from 'react-router';

const headers: GridHeader[] = [
    {
        title: "Sr. No.",
        class: 'text-center',
    },
    {
        title: "Created Date",
        class: 'text-center',
    },
    {
        title: "Category",
        class: 'text-center'
    },
    // {
    //     title: "Parent category",
    //     class: 'text-center'
    // },
    {
        title: "Action",
        class: "text-center freeze-column",
        isSorting: false,
        isFilter: false,
        isFreeze: true,
    },
];

const TabSubCategories = () => {
    const { handleSubmit, formState: { errors, isValid }, control, watch, register, setValue, reset } = useForm<any>({});
    const watchAllFields = watch();
    const [showAddCat, setShowAddCat] = useState(false);
    const handleCloseAddCat = () => { reset({}); setValue("name", ""); setEditData(""); setFile(""); setFileName(""); setShowAddCat(false) };
    const handleShowAddCat = () =>  {  reset({});
    setEditData(""); setShowAddCat(true);}
    const [showViewCat, setShowViewCat] = useState(false);
    const handleCloseViewCat = () => setShowViewCat(false);
    const [rows, setRows] = useState<GridRow[]>([]);
    const [ShowLoader, setShowLoader] = useState(false);
    const [ShowPageLoader, setShowPageLoader] = useState(false);
    const rowCompute = useRef<GridRow[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const pageCount = useRef<number>(0);
    const [editData, setEditData] = useState<any>();
    const [showDeleteModal, setDeleteModal] = useState<boolean>(false);
    const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
    const [fileName, setFileName] = useState<string>("");
    const [file, setFile] = useState<any>();
    const { id } = useParams()

    useEffect(() => {
        getCategoryOptions();
    }, [])

    const getCategories = (page: number, keyword?: string, startDate?: Date, endDate?: Date) => {
        pageCount.current = page;
        setShowLoader(true);
        WebService.getAPI({
            action: `sub-categories/list/${page}/${id}?keyword=${keyword ? keyword : ""}&date_from=${startDate ? startDate : ""}&date_to=${endDate ? endDate : ""}`,
            body: null,
        })
            .then((res: any) => {
                setShowLoader(false);
                let rows: GridRow[] = [];
                if (page == 1) {
                    setTotalCount(res.count);
                }
                let startCount = (page - 1) * 10 + 1;
                if (page == 1) {
                  setTotalCount(res.count);
                }
        
                for (var i in res.list) {
                    let columns: GridColumn[] = [];
                    // columns.push({ value: `${page - 1}${Number(i) + 1}` });
                    columns.push({ value: `${startCount++}` });
                    columns.push({ value: res.list[i].created_at && HelperService.getFormatedDate(res.list[i].created_at) });
                    columns.push({ value: res.list[i].name });
                    // columns.push({ value: res.list[i].parent_category ? res.list[i].parent_category : "N/A" });
                    columns.push({ value: actionList(res.list[i]), type: "COMPONENT", });
                    rowCompute.current.push({ data: columns });
                    rows.push({ data: columns });
                }
                rowCompute.current = rows;
                setRows(rowCompute.current);
            })
            .catch((e) => {
                setShowLoader(false);
            });
    };

    const getCategoryOptions = () => {
        WebService.getAPI({
            action: `categories/detail/${id}`,
            body: null,
        })
            .then((res: any) => {
                let temp: any[] = [];
                temp.push({ ...res, value: res.name });
                setCategoryOptions([{ ...res, value: res.name }]);
                setValue("parent_id", res.id)
            })
            .catch((e) => { });
    };

    const actionList = (value: any) => {
        return (
            <div className="action-btns">
                <button onClick={() => onEdit(value)} className="btn btn-edit"><MdOutlineModeEditOutline className="icon" /></button>
                <button className="btn btn-delete" onClick={() => onConfirmDelete(value)}><FaRegTrashAlt className="icon" /></button>
            </div>
        );
    };

    const onEdit = (val: any) => {
        setEditData(val)
        reset(val);
        setValue("parent_id", id)
        setShowAddCat(true);
    };



    const onConfirmDelete = (val: any) => {
        setEditData(val);
        setDeleteModal(true);
    };

    const onDelete = () => {
        setDeleteModal(false);
        setShowPageLoader(true);
        WebService.deleteAPI({
            action: `categories/delete/${editData?.id}`,
            body: null,
        })
        .then((res: any) => {
            setShowPageLoader(false);
            toast.success(res.message);
            setEditData("");
            getCategories(1);
        })
        .catch((e) => {
            setShowPageLoader(false);
            setEditData("");
        });   
    };

    const onSaveCategory = (data: any) => {
        if(data.id){
            WebService.putAPI({
                action: `categories/${data.id}`,
                body: {...data,parent_id:id},
                id: "category_actions"
            })
            .then((res: any) => {
                if (file) {
                    uploadImage(res.id)
                } else {
                    toast.success(res.message);
                    handleCloseAddCat();
                    getCategories(1);
                }
            })
            .catch((e) => { });   
        } else {
            WebService.postAPI({
                // action: `categories`,
                action: `/sub-categories`,
                
                body: {...data,parent_id:id},
                id: "category_actions"
            })
            .then((res: any) => {
                if (file) {
                    uploadImage(res.id)
                } else {
                    toast.success(res.message);
                    handleCloseAddCat();
                    getCategories(1);
                }
            })
            .catch((e) => { });   
        }
    };

    const validateImage = (e: any) => {
        const { files } = e.target;
        if (files && files[0]) {
            var sizeInBytes = files[0].size;
            var sizeInKb = sizeInBytes / 1024;
            var sizeInMb = sizeInKb / 1024;
            if (sizeInMb > 2) {
                toast.warn("Image must be less than 2 MB!", { theme: "colored" });
                return;
            }
            var isImageExtensions = /(\.(jpg|jpeg|png|gif|svg))$/i;
            if (!isImageExtensions.test(files[0].name)) {
                toast.warn("Invalid File Type", { theme: "colored" });
                return;
            }
            const blob = URL.createObjectURL(files[0]);
            setFile(files[0]);
            setFileName(blob);
        }
    };

    const uploadImage = (id: string) => {
        WebService.fileUploadAPI({
            action: `categories/upload`,
            key: "image",
            file: file,
            body: { id: id },
        })
            .then((res: any) => {
                toast.success(res.message);
                handleCloseAddCat();
                getCategories(1);
            })
            .catch((error: any) => { });
    };

    return (
        <>
            <div className="app-page page-dashboard">
                <Loader show={ShowPageLoader} />
                <PageTitle title="Manage Sub Category" backArrow={true} />
                <DeleteModal isShow={showDeleteModal} close={() => { setDeleteModal(false); setEditData(""); }} onDelete={() => { onDelete() }} />
                <Row className="mb-3">
                    <Col lg={8}></Col>
                    <Col lg={4} className="text-end">
                        <Button className="btn-brand-1" onClick={handleShowAddCat}>+ Add Sub Category</Button>
                    </Col>
                </Row>
                <Card className="table-card">
                    <Grid rows={rows} headers={headers} ShowLoader={ShowLoader} count={totalCount} onPageChange={getCategories} errorMessage={"No Sub Category Found"} showSearch={true} showDateFilter={true} />
                </Card>

                {/* Add Category  */}
                <Offcanvas show={showAddCat} onHide={handleCloseAddCat} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{editData ? "Edit " : "Add "} Sub Category</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='form-style'>
                        <form onSubmit={handleSubmit(onSaveCategory)}>
                            <Form.Group className="mb-3">
                                <Form.Label> Sub Category</Form.Label>
                                <Form.Control type="text" value={watchAllFields.name}
                                    {...register("name", {
                                        required: true,
                                    })} />
                                {errors.name && (
                                    <div className="login-error">
                                        <Label
                                            title={"Please Enter Sub Category Name"}
                                            modeError={true}
                                        />
                                    </div>
                                )}
                            </Form.Group>

                            {/* {!fileName && <Controller
                                control={control}
                                name="parent_id"
                                rules={{
                                    required: false,
                                }}
                                render={({
                                    field
                                }) => (
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Parent category</Form.Label>
                                        <ShancoSelect onChange={(e: any) => { field.onChange(e.id); }} options={categoryOptions} selected={watchAllFields.parent_id} isDisable={true} />
                                    </Form.Group>
                                )}
                            />} */}

                            <Button className='btn-brand-1 w-100' type='submit' id="category_actions">Save</Button>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>

                {/* View  */}
                <Offcanvas show={showViewCat} onHide={handleCloseViewCat} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Detail</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='form-style'>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Category</Form.Label>
                                <p>Category 1</p>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Parent category</Form.Label>
                                <p>NA</p>
                            </Form.Group>
                            <Button className='btn-brand-1 w-100'>Save</Button>
                        </Form>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    )
}
export default TabSubCategories;