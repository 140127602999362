// import { Button } from "../../components/Button/Button";
import { Button, Col } from "react-bootstrap";
// import "./DeleteModal.css";
import { ExclamationCircle } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Label } from "../../components/Common/Label/Label";
import { useEffect } from "react";

interface PropData {
  close: any;
  onDelete?: any;
  Data?: any;
  isShow: any;
  onSave: any;
}

const PdfCommentAddModal = (props: PropData) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    register,
    reset,
  } = useForm<any>({});

  useEffect(() => {
    if (props?.Data) {
      reset(props?.Data);
    }
  }, []);

  const onCancel = () => {
    props.close();
  };

  const onSubmit = (data: any) => {
      const formattedData = {
      content: data.content,
      style: data.style ?data.style : { 

        left: "0%",
        top: "0%"
      }
    };
    props.onSave(formattedData);
  };
  

  return (
    <>
      {props.isShow && (
        <Modal
          show={props.isShow}
          onHide={onCancel}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="delete-modal mt-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-style  ms-4 me-4">
                <Col lg={12}>
                  <label className="mt-2">Comment</label>

                  <div className="input-group mb-1 mt-2">
                    <textarea
                      className="form-control ps-3 p-2"
                      placeholder="Text"
                      {...register("content", { required: true })}
                      rows={5}
                      cols={10}
                    />
                    {errors.content && (
                      <div className="login-error">
                        <Label
                          title={"Header text is required"}
                          modeError={true}
                        />
                      </div>
                    )}
                  </div>
                </Col>

                <div className="d-flex justify-content-center mt-4 mb-4">
                  <Button
                    className="btn-brand-1"
                    type="submit"
                    id="popup-confirm-button"
                  >
                    Save
                  </Button>
                  <span className="px-2"></span>
                  <Button
                    className="btn btn-dark d"
                    onClick={onCancel}
                    id="popup-cancel-button"
                  >
                    Cancel
                  </Button>
                  <span className="px-2"></span>
                  {props.Data && (
                    <Button
                      className="btn btn-danger d"
                      onClick={() => props.onDelete()}
                      id="popup-cancel-button"
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PdfCommentAddModal;
