import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import WebService from "../../Services/WebService";
// import NumberListIcon from "../../assets/images/number-list-icon.svg";s
import { useSelector } from "react-redux";
import { RootState } from "../../config/Store";
import { reduxState } from "../../reducer/CommonReducer";
// import PageLoader from "../Common/PageLoader/PageLoader";
import { MdComment, MdOutlineKeyboardBackspace } from "react-icons/md";
import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";
import PageTitle from "../../components/Common/PageTitle";
import PdfCommentAddModal from "./PdfCommentAddModal";

const PdfDocumentPage = () => {
  const [isPdfcomment, setPdfComment] = useState<any[]>([]);
  const commonData: any = useSelector<RootState, reduxState>(
    (state: any) => state.commonData
  );
  const [showContent, setShowContent] = useState<any>();
  const navigate = useNavigate();
  const [pdfData, setPdfData] = useState<any>();
  const [ShowPageLoader, setShowPageLoader] = useState(false);
  const [ICON, setICON] = useState<any>();
  const [showComment, setShowComment] = useState<any>();
  const [isShowCommentIndex, setShowCommentIndex] = useState<any>();
  const [isDownloadable, setDownloadable] = useState<any>(false);
  const [headerData, setHeaderData] = useState<any>();
  const [isData, setData] = useState<any[]>([]);
  const [indexComment, setIndexComment] = useState<any>(null);
  const [showAddCommentModal, setShowAddCommentModal] = useState<any>({
    isShow: false,
    data: null,
  });

  const { documentId, page_index }: any = useParams();

  useEffect(() => {
    ReadPdf(documentId);
  }, []);

  const ReadPdf = (id?: any) => {
    setShowPageLoader(true);
    WebService.postAPI({
      action: `read-docx-new/${id}`,
      // body: { file_name: file_Name },
    })
      .then((res: any) => {
        setICON(res.icon);
        setShowPageLoader(false);
        setPdfData(res.images);
        var Comentdata = JSON.parse(res.html_content_pages);
        setData(Comentdata);
        setPdfComment(Comentdata);

        var HeaderData = JSON.parse(res.paragraph_headers);
        setHeaderData(HeaderData);
      })
      .catch(() => {
        setShowPageLoader(false);
      });
  };

  const handleCommentClick = (commentIndex: any) => {
    setShowComment(!showComment);
    setShowCommentIndex(commentIndex);
  };

  const onDownloadDocument = () => {
    WebService.postAPI({
      action: `read-document/${commonData.documentData.document_id}`,
    })
      .then((res: any) => {
        if (res && res.url) {
          window.open(res.url, "_self");
        }
      })
      .catch(() => {
        toast.error("Download Failed");
      });
  };

  // const onDrag = (id: any, mainIndex: any, commentIndex: any, data: any) => {
  //   dragElement(document.getElementById(id));

  //   function dragElement(elmnt: any) {
  //     var left: any;
  //     var top: any;
  //     var pos1 = 0,
  //       pos2 = 0,
  //       pos3 = 0,
  //       pos4 = 0;
  //     if (document.getElementById(elmnt.id + "header")) {
  //       /* if present, the header is where you move the DIV from:*/
  //       var value = document.getElementById(elmnt.id + "header");
  //       if (value != null) {
  //         value.onmousedown = dragMouseDown;
  //       }
  //     } else {
  //       /* otherwise, move the DIV from anywhere inside the DIV:*/
  //       elmnt.onmousedown = dragMouseDown;
  //     }

  //     function dragMouseDown(e: any) {
  //       e = e || window.event;
  //       e.preventDefault();
  //       // get the mouse cursor position at startup:
  //       pos3 = e.clientX;
  //       pos4 = e.clientY;
  //       document.onmouseup = closeDragElement;
  //       // call a function whenever the cursor moves:
  //       document.onmousemove = elementDrag;
  //     }

  //     function elementDrag(e: any) {
  //       e = e || window.event;
  //       e.preventDefault();
  //       // calculate the new cursor position:
  //       pos1 = pos3 - e.clientX;
  //       pos2 = pos4 - e.clientY;
  //       pos3 = e.clientX;
  //       pos4 = e.clientY;
  //       // set the element's new position:
  //       elmnt.style.top = elmnt.offsetTop - pos2 + "px";
  //       elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
  //       top = elmnt.offsetTop - pos2 + "px";
  //       left = elmnt.offsetLeft - pos1 + "px";
  //     }

  //     function closeDragElement() {
  //       /* stop moving when mouse button is released:*/
  //       document.onmouseup = null;
  //       document.onmousemove = null;

  //       const temp = isData.map((item: any, j: number) => {
  //         if (j == mainIndex) {
  //           if (item.comments && item.comments.length) {
  //             item.comments = item.comments.map((res: any, i: number) => {
  //               if (i == commentIndex) {
  //                 res.content = data.content;
  //                 res.style.left = left;
  //                 res.style.top = top;
  //               }

  //               return { ...res };
  //             });
  //           }
  //         }
  //         return { ...item };
  //       });
  //       setData([...temp]);

  //       // const HandleOnSave = (data: any) => {
  //       WebService.putAPI({
  //         action: `products/pdf-update-comment-details/${documentId}`,
  //         body: temp,
  //       })
  //         .then((res: any) => {
  //           toast.success(res.message);
  //         })
  //         .catch(() => {});
  //       // };
  //     }
  //   }
  // };

  const onDrag = (
    id: any,
    mainIndex: any,
    commentIndex: any,
    data: any,
    index: any
  ) => {
    dragElement(document.getElementById(id));

    function dragElement(elmnt: any) {
      let left: any;
      let top: any;
      let isDragging = false;
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

      if (document.getElementById(elmnt.id + "header")) {
        const value = document.getElementById(elmnt.id + "header");
        if (value != null) {
          value.onmousedown = dragMouseDown;
        }
      } else {
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e: any) {
        e = e || window.event;
        e.preventDefault();
        isDragging = false;
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }
      // console.log("elmnt",elmnt);

      function elementDrag(e: any) {
        e = e || window.event;
        e.preventDefault();
        isDragging = true;
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        // top = elmnt.offsetTop - pos2 + "px";
        // left = elmnt.offsetLeft - pos1 + "px";

        const newTop = elmnt.offsetTop - pos2;
        const newLeft = elmnt.offsetLeft - pos1;

        const pageElement = document.getElementById(`page-${index + 1}`);
        const clientHeight: any = pageElement?.clientHeight;
        const clientWidth: any = pageElement?.clientWidth;

        top = (newTop / clientHeight) * 100 + "%";
        left = (newLeft / clientWidth) * 100 + "%";
      }

      function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;

        // Only update position if dragging occurred
        if (isDragging) {
          const updatedData = isData.map((item: any, j: number) => {
            if (j === mainIndex) {
              if (item.comments && item.comments.length) {
                item.comments = item.comments.map((res: any, i: number) => {
                  if (i === commentIndex) {
                    res.content = data.content;
                    res.style.left = left;
                    res.style.top = top;
                  }
                  return { ...res };
                });
              }
            }
            return { ...item };
          });

          setData([...updatedData]);

          WebService.putAPI({
            action: `products/pdf-update-comment-details/${documentId}`,
            body: updatedData,
          })
            .then((res: any) => {
              toast.success(res.message);
            })
            .catch(() => {
              toast.error("Failed to save position.");
            });
        }
      }
    }
  };

  const handleDoubleClick = (data: any, index: any) => {
    const PageIndex: any = page_index;
    setShowAddCommentModal({ isShow: true, data: data });
    setIndexComment(index);
  };

  const handleDoubleClickAdd = () => {
    setShowAddCommentModal({ isShow: true, data: null });
    setIndexComment(isPdfcomment[page_index].comments.length);
  };

  const handleCommentSave = (data: any, index: number) => {
    var pdfData: any = isPdfcomment;
    pdfData[page_index].comments[index] = data;
    setShowAddCommentModal({ isShow: false, data: null });
    setIndexComment(null);
    handleSave(pdfData);
  };

  // const handleDeleteComment = (index: number) => {
  //   setPdfComment((prevPdfComments) => {
  //     const updatedPdfData = [...prevPdfComments];
  //     updatedPdfData[page_index]?.comments.splice(index, 1);
  //     setShowAddCommentModal({ isShow: false, data: null });
  //     setIndexComment(null);

  //     return updatedPdfData;
  //   });
  // };

  const handleDeleteComment = (index: number) => {
    var pdfData: any = isPdfcomment;
    pdfData[page_index]?.comments.splice(index, 1);

    setShowAddCommentModal({ isShow: false, data: null });
    setIndexComment(null);
    handleSave(pdfData);
  };

  const handleSave = (data: any) => {
    const FormData: any = data;
    WebService.putAPI({
      action: `products/pdf-update-comment-details/${documentId}`,
      body: FormData,
    })
      .then((res: any) => {
        toast.success(res.message);
      })
      .catch(() => {
        toast.error("Failed to save position.");
      });
  };

  // Usage

  return (
    <div style={{ backgroundColor: "#7a7f85", minHeight: "100vh" }}>
      <Container style={{ maxWidth: "1150px" }}>
        <div className="doc-header-section d-flex justify-content-end align-items-center mb-4">
          {/* <div style={{ fontSize: "21px" }}>Viewing as Admin</div> */}
          {isDownloadable ? (
            <a onClick={onDownloadDocument} className="btn btn-dark ms-3">
              Download
            </a>
          ) : (
            ""
          )}
          {/* <div className="ml-md-3 ms-4 mt-2" data-fix="shan@shan">
              <img
                style={{
                  boxShadow: "0 1px 12px rgba(0, 0, 0, 0.3)",
                  width: "130px",
                }}
                // src={commonData?.category.icon}
                src={ICON ? ICON : commonData?.category.icon}
                alt="Logo"
              />
            </div> */}
        </div>

        <div>
        <a
      onClick={() => navigate(-1)} // Go back one step in history
      style={{ color: "white", cursor: "pointer" }}
      className="left-arrow mr-3 text-brand"
    >
      <MdOutlineKeyboardBackspace size={24} />
    </a>

          <Button
            onClick={() => handleDoubleClickAdd()}
            className="left-arrow mr-3 text-brand float-end me-4 mb-3"
            style={{ backgroundColor: "#294273", color: "white", border: "0" }}
          >
            Add Comment
          </Button>
        </div>

        {pdfData &&
          pdfData.length > 0 &&
          pdfData.map((item: any, index: any) => {
            if (page_index == index) {
              return (
                <div
                  // id={`page-1`}
                  key={index}
                  id={`page-${index + 1}`}
                  className="page-card card shadow-sm mb-4"
                  style={{
                    // overflow: "hidden",
                    backgroundColor: "white",
                    position: "relative",
                    width: 1100,
                  }}
                >
                  <div
                    className="card-header"
                    data-fix="shan@shan"
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>Page: {index + 1}</div>
                    <div className="font-weight-normal" data-fix="shan@shan">
                      <span
                        style={{ cursor: "pointer" }}
                        className="show-topics"
                        title="Topics"
                        onClick={() => setShowContent(!showContent)}
                      >
                        <img
                          src={"NumberListIcon"}
                          alt="Number List"
                          style={{ width: "35px" }}
                        />
                      </span>
                    </div>
                  </div>
                  <span
                    className="watermark"
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "40%",
                      transform: "translate(-50%, -50%) rotate(-30deg)",
                      opacity: 0.2,
                      fontSize: "125px",
                      fontWeight: "bold",
                    }}
                  >
                    Shan &amp; Co
                  </span>

                  {isPdfcomment &&
                    isPdfcomment[index] &&
                    isPdfcomment[index]?.comments?.length > 0 &&
                    isPdfcomment[index]?.comments?.map(
                      (ite: any, commentIndex: any) => {
                        return (
                          <>
                            <div
                              key={`comment-${index}-${commentIndex}`}
                              id={`commentBtn-${commentIndex}`}
                              onMouseEnter={() =>
                                handleCommentClick(commentIndex)
                              }
                              onMouseMove={() => {
                                onDrag(
                                  `commentBtn-${commentIndex}`,
                                  index,
                                  commentIndex,
                                  ite,
                                  index
                                );
                              }}
                              onMouseLeave={() => setShowComment(false)}
                              style={{
                                position: "absolute",
                                left: ite?.style.left,
                                top: ite?.style.top,
                                // marginTop: "10px",
                                background: "yellow",
                                cursor: "pointer",
                              }}
                            >
                              <MdComment
                                style={{ fontSize: "30px" }}
                                onDoubleClick={() =>
                                  handleDoubleClick(ite, commentIndex)
                                }
                              />
                            </div>

                            {showComment &&
                              commentIndex == isShowCommentIndex && (
                                <div
                                  key={`comment-${index}-${commentIndex}`}
                                  className="topics-panel"
                                  style={{
                                    left: ite?.style.left,
                                    top: `calc(${ite?.style.top} + 3%)`,
                                    zIndex: "1",
                                    position: "absolute",
                                    margin: "0 auto !important",
                                  }}
                                >
                                  <div className="card mb-4">
                                    <div
                                      key={commentIndex}
                                      className="card-body toc-body font-22 font-bold heading-font"
                                      style={{
                                        overflowY: "scroll",
                                        height: "auto",
                                        width: "auto",
                                        backgroundColor: "yellow",
                                      }}
                                    >
                                      {ite.content}
                                    </div>
                                  </div>
                                </div>
                              )}
                          </>
                        );
                      }
                    )}

                  <div
                    className="card-body page-card-body ms-3"
                    id={`page-${index}`}
                  >
                    <span style={{ marginBottom: "10px" }}>
                      <img
                        key={item.image}
                        src={item.url}
                        alt={`Image ${index}`}
                        style={{
                          top: `${5}%`,
                          left: `${1}%`,
                          width: `100%`,
                        }}
                      />
                    </span>
                  </div>
                </div>
              );
            }
          })}
        {showAddCommentModal.isShow && (
          <PdfCommentAddModal
            close={() =>
              setShowAddCommentModal({
                isShow: false,
              })
            }
            isShow={showAddCommentModal.isShow}
            Data={showAddCommentModal.data}
            onSave={(element: any) => {
              handleCommentSave(element, indexComment);
            }}
            onDelete={() => {
              handleDeleteComment(indexComment);
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default PdfDocumentPage;
